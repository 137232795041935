import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "./Faq.css";

const faqData = [
  {
    question: "Quais planos estão disponíveis?",
    answer: (
      <>
        Trabalhamos com diversas opções de planos para atender diferentes perfis e necessidades, incluindo planos individuais, familiares, coletivo por adesão e empresariais. 
        Acesse nossa <Link to="/planos">página de planos</Link> para ver todas as opções disponíveis, comparar coberturas e escolher a que melhor atende você.
      </>
    ),
  },
  {
    question: "Como faço para baixar o aplicativo?",
    answer: (
      <>
        Nosso aplicativo está disponível para download na Play Store (Android) e App Store (iOS). 
        Com ele, você pode acessar sua carteirinha digital, verificar a rede credenciada, agendar consultas e muito mais. 
        Para baixar, acesse a <Link to="/aplicativo">página de download</Link> e siga as instruções para instalar no seu dispositivo.
      </>
    ),
  },
  {
    question: "Como solicitar autorização de guias?",
    answer: (
      <>
        Caso precise de autorização para exames ou procedimentos médicos, você pode solicitá-la diretamente pelo nosso portal ou entrando em contato com nossa central de atendimento. 
        Acesse a <Link to="/autorizacao">página de autorização</Link> para saber quais documentos são necessários e como enviar seu pedido.
      </>
    ),
  },
  {
    question: "Como consultar a rede credenciada?",
    answer: (
      <>
        Nossa rede credenciada conta com hospitais, clínicas e laboratórios de referência. 
        Para conferir quais unidades atendem o seu plano e encontrar a mais próxima de você, visite a <Link to="/rede">página da rede credenciada</Link> e utilize nosso sistema de busca.
      </>
    ),
  },
  {
    question: "Como gerar a segunda via do boleto?",
    answer: (
      <>
        Se você precisa emitir a segunda via do seu boleto, acesse nosso portal de pagamentos e informe seus dados. 
        Assim, você poderá baixar o boleto atualizado e evitar atrasos no pagamento. 
        Acesse a <Link to="/boleto">página de boletos</Link> para obter mais informações.
      </>
    ),
  },
  {
    question: "A telemedicina está disponível?",
    answer: (
      <>
        Sim! Com nossa telemedicina, você pode realizar consultas online sem sair de casa. 
        Esse serviço é ideal para atendimentos rápidos e acompanhamento médico, oferecendo mais comodidade e segurança. 
        Para saber mais e agendar sua consulta virtual, visite a <Link to="/telemedicina">página de telemedicina</Link>.
      </>
    ),
  },
  {
    question: "Como agendar uma consulta?",
    answer: (
      <>
        O agendamento de consultas pode ser feito diretamente com nossa equipe de atendimento. 
        Ligue para nossa central ou acesse a <Link to="/consultas">página de agendamentos</Link> para verificar horários disponíveis e marcar sua consulta de forma rápida e prática.
      </>
    ),
  },
  {
    question: "Onde posso acessar meu cartão de beneficiário?",
    answer: (
      <>
        Seu cartão de beneficiário está disponível digitalmente no nosso portal e aplicativo. 
        Com ele, você pode acessar serviços médicos sem a necessidade do cartão físico. 
        Para visualizar e baixar sua carteirinha digital, acesse a <Link to="/cartao-beneficiario">página do cartão de beneficiário</Link>.
      </>
    ),
  },
];

const FaqItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className="faq-item">
      <div className="faq-question" onClick={onClick}>
        <p>{question}</p>
        <span className={`arrow ${isOpen ? "open" : ""}`}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            {isOpen ? (
              <line x1="5" y1="12" x2="19" y2="12" />
            ) : (
              <>
                <line x1="12" y1="5" x2="12" y2="19" />
                <line x1="5" y1="12" x2="19" y2="12" />
              </>
            )}
          </svg>
        </span>
      </div>
      <CSSTransition in={isOpen} timeout={300} classNames="faq" unmountOnExit>
        <div className="faq-answer">{answer}</div>
      </CSSTransition>
    </div>
  );
};

export const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <div className="bg-duvidas-title titulo-duvidas">
        <h2>DÚVIDAS FREQUENTES</h2>
      </div>
      <section className="faq-section">
        {faqData.map((item, index) => (
          <FaqItem
            key={index}
            question={item.question}
            answer={item.answer}
            isOpen={openIndex === index}
            onClick={() => toggleFaq(index)}
          />
        ))}
      </section>
    </div>
  );
};
