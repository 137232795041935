import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";

import logo from "../../assets/img-header/logo.webp";
import menuicon from "../../assets/img-header/menuicon.webp";

import NewNavOptions from "./NewNavOptions/NewNavOptions";

const Header = () => {
    const location = useLocation();

    const dropDownRef = useRef(null);
    const [isActive, setIsActive] = useState(false);

    const onClick = () => {
        setIsActive(!isActive);
    };

    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <header>

            <div className="header">

                <div className="logo__container">

                    <Link to="/" className="navigation__logo__link">
                        <img  src={logo} className="header__logo" alt="Logo GoCare" />
                    </Link>
                    <p><span>SUA SAÚDE</span><br /> É UM PLANO DE AGORA!</p>

                </div>

                <div className="navigation">

                    <button onClick={onClick} className="menu-button">
                        <span>Menu</span>
                        <img  src={menuicon} alt="Menu" />
                    </button>

                    <nav className={`menu__nav ${isActive ? 'open' : ''}`} ref={dropDownRef}>

                        <ul className="menu__ul">

                            <li className="menu__li">
                                <Link to="/" className={`header__link ${location.pathname === '/' ? 'active' : ''}`}>
                                    <span className="hover">Início</span>
                                </Link>
                            </li>

                            <li className="menu__li">
                                <Link to="/sobre" className={`header__link ${location.pathname === '/sobre' ? 'active' : ''}`}>
                                    <span className="hover">Sobre Nós</span>
                                </Link>
                            </li>

                            <li className="menu__li">
                                <Link to="/planos" className={`header__link ${location.pathname === '/planos' ? 'active' : ''}`}>
                                    <span className="hover">Planos</span>
                                </Link>
                            </li>

                            <li className="menu__li">
                                <Link to="/cliente" className={`header__link ${location.pathname === '/cliente' ? 'active' : ''}`}>
                                    <span className="hover">Seja Cliente</span>
                                </Link>
                            </li>

                            <li className="menu__li">
                                <Link to="/duvidas" className={`header__link ${location.pathname === '/duvidas' ? 'active' : ''}`}>
                                    <span className="hover">Dúvidas</span>
                                </Link>
                            </li>

                            <li className="menu__li new-nav-options">
                                <Link to="/aplicativo" className={`header__link ${location.pathname === '/aplicativo' ? 'active' : ''}`}>
                                    <span className="hover">Baixe o App</span>
                                </Link>
                            </li>

                            <li className="menu__li new-nav-options">
                                <Link to="/rede" className={`header__link ${location.pathname === '/rede' ? 'active' : ''}`}>
                                    <span className="hover">Rede Credenciada</span>
                                </Link>
                            </li>

                            <li className="menu__li new-nav-options">
                                <Link to="/autorizacao" className={`header__link ${location.pathname === '/autorizacao' ? 'active' : ''}`}>
                                    <span className="hover">Autorização</span>
                                </Link>
                            </li>

                            <li className="menu__li new-nav-options">
                                <Link to="/boleto" className={`header__link ${location.pathname === '/boleto' ? 'active' : ''}`}>
                                    <span className="hover">Boleto</span>
                                </Link>
                            </li>

                            <li className="menu__li new-nav-options">
                                <Link to="/telemedicina" className={`header__link ${location.pathname === '/telemedicina' ? 'active' : ''}`}>
                                    <span className="hover">Telemedicina</span>
                                </Link>
                            </li>

                            <li className="menu__li new-nav-options">
                                <Link to="/consultas" className={`header__link ${location.pathname === '/consultas' ? 'active' : ''}`}>
                                    <span className="hover">Consultas</span>
                                </Link>
                            </li>

                            <li className="menu__li new-nav-options">
                                <Link to="/cartao-beneficiario" className={`header__link ${location.pathname === '/cartao-beneficiario' ? 'active' : ''}`}>
                                    <span className="hover">Cartão de Beneficiário</span>
                                </Link>
                            </li>

                        </ul>

                    </nav>

                </div>

            </div>

            <NewNavOptions />

        </header>

    );
};

export default Header;
