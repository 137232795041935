import "./Duvidas.css";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { Faq } from "./Faq/Faq.js";
import usePageTracking from "../../../hooks/usePageTracking.js";
import wpplogo from "../../../assets/img-main/wpplogo.webp";
import handshake from "../../../assets/img-main/handshake.webp";

export const Duvidas = () => {
  const navigate = useNavigate();
  usePageTracking();

  return (
    <section className="duvidas">
      <Helmet>
        <link rel="canonical" href="https://www.gocarelitoral.com.br/contato" />
        <title>Dúvidas Frequentes | GoCare Saúde Litoral</title>
        <meta
          name="description"
          content="Encontre respostas para as dúvidas mais comuns sobre os planos de saúde da GoCare Saúde no Litoral. Saiba tudo sobre carências, coberturas e benefícios dos nossos planos de saúde na Baixada Santista."
        />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "GoCare Saúde Litoral",
            url: "https://gocarelitoral.com.br",
            logo: "https://gocarelitoral.com.br/static/media/logo.ae940211769127fa78a0.webp",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+55 13 98126-0507",
              contactType: "sales",
              areaServed: "BR",
              availableLanguage: ["Portuguese"],
              description:
                "Encontre respostas para as dúvidas mais comuns sobre os planos de saúde da GoCare Saúde no Litoral. Saiba tudo sobre carências, coberturas e benefícios dos nossos planos de saúde na Baixada Santista.",
            },
            sameAs: [
              "https://www.facebook.com/gocare.litoral",
              "https://www.instagram.com/gocare.litoral",
            ],
          })}
        </script>
      </Helmet>

      <div className="duvidas-vendas">
        <div className="bg-duvidas-title titulo-duvidas">
          <h2>ATENDIMENTO EXCLUSIVO PARA VENDAS</h2>
        </div>

        <div className="margin-duvidas">
          <p className="vendas-text">
            Quer saber mais sobre nossos planos e como escolher a melhor opção
            para você? Nossos canais de atendimento estão prontos para te
            ajudar!
          </p>

          <div className="canais-vendas-container">
            <div className="canais-vendas">
              <button
                className="canais-vendas-buttons canal__whatsapp"
                onClick={() =>
                  window.open(
                    "https://wa.me/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20GoCare%20Litoral.",
                    "_blank"
                  )
                }
              >
                <img src={wpplogo} alt="Logo do whatsapp" />
                <p>Solicitar Cotação</p>
              </button>
            </div>

            <div className="canais-vendas">
              <button
                className="canais-vendas-buttons"
                onClick={() => navigate("/cliente")}
              >
                <img src={handshake} alt="Icone de vendas" />
                <p>Solicitar Cotação</p>
              </button>
            </div>
            
          </div>

          <p className="vendas-text">
            Se preferir, envie um e-mail para{" "}
            <a
              className="vendas-atendimento-email"
              href="mailto:vendas@gocarelitoral.com.br"
            >
              vendas@gocarelitoral.com.br
            </a>{" "}
            com sua solicitação e nossa equipe responderá o mais breve possível.
          </p>

        </div>

      </div>
      <Faq />
    </section>
  );
};
